import React from 'react';
import './EntityListTab.css';
import EntityList from './EntityListTab/EntityList.jsx';
import EntityTable from './EntityListTab/EntityTable.jsx';

import Modal from '../Modal.jsx';
import queryString from 'query-string';

import CompareProp from '../CompareProp.js';
import { withRouter } from 'react-router-dom';

class EntityListTab extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            entitySortProp: 'name',
            sortReverse: false,
            tagSelector: false,
            tagList: [],
            operation: 'all',
            logic: 'or',
        }
    }
    fields = [
        'parent-op',
        'white-op',
        'whitelistTags',
        'black-op',
        'blacklistTags',
    ]
    
    skillGroups = [
        'none',
        'Academic',
        'Combat',
        'Espionage',
        'Social',
        'Special',
    ];
    componentDidMount = () => {
        this.pushHistory();
    }
    pushHistory = (e) => {
        let target = e?.target;
        // console.log(target);
        let options = queryString.parse(this.props.location.search);
        let tagSettings = {};
        this.fields.forEach(field => {
            let value = '';
            let element = document.getElementById(field)
            if(!!element) {
                value = element.value;
            }
            else if(!!options[field]) {
                value = options[field];
            }
            tagSettings[field] = value;
        })
        // console.log(options['white-op'], tagSettings['white-op']);
        tagSettings['parent-op'] = tagSettings['parent-op'] || 'doc';
        tagSettings['white-op'] = tagSettings['white-op'] || 'any';
        tagSettings['black-op'] = tagSettings['black-op'] || 'any';
        let whitelist = options.whitelistTags?.split(',') || [];
        let blacklist = options.blacklistTags?.split(',') || [];
        if(!!target) {
            let listname = target.dataset.list;
            // console.log(target.checked)
            if(target.checked) {
                if(listname === 'whitelist') {
                    whitelist.push(target.value);
                }
                if(listname === 'blacklist') {
                    blacklist.push(target.value);
                }
            }
            if(!target.checked) {
                // console.log(target.value)
                // console.log(listname)
                if(listname === 'whitelist') {
                    whitelist = whitelist.filter(x => x !== target.value);
                }
                if(listname === 'blacklist') {
                    blacklist = blacklist.filter(x => x !== target.value);
                }
            }
        }
        whitelist = whitelist.filter(x => !!x);
        blacklist = blacklist.filter(x => !!x);
        tagSettings['whitelistTags'] = whitelist.join(',');
        tagSettings['blacklistTags'] = blacklist.join(',');
        // console.log(tagSettings);
        let newSubpath = '?';
        this.fields.forEach(field => {
            newSubpath += field + '=' + tagSettings[field] + '&';
        })
        newSubpath = newSubpath.substring(0, newSubpath.length - 1);
        // console.log(newSubpath);
        this.props.history.push(newSubpath);
    }
    addNewEntity = (e) => {
        let options = queryString.parse(this.props.location.search);
        let whitelist = options.whitelistTags.split(',');
        whitelist = whitelist.filter(x => !!x);
        this.props.sendMessage({
            operation: 'add new entity',
            tags: whitelist,
        });
    }
    confirmRemoveEntity = (e) => {
        let id = e.target.dataset.id;
        let name = e.target.dataset.name;
        if(window.confirm('Are you sure you want to permanently delete ' + name + '?')){
            this.removeEntity(id);
        }
    }
    removeEntity = (id) => {
        this.props.sendMessage({
            operation: 'remove entity',
            entityid: id,
        });
    }
    changeStat = (e) => {
        let entityid = e.target.dataset.entityid;
        let statid = e.target.dataset.statid;
        let statgroup = e.target.dataset.statgroup;
        let value = e.target.value;
        let valueType = e.target.dataset.valuetype;
        // console.log(valueType);
        this.props.sendMessage({
            operation: 'change entity stat value',
            entityid,
            statgroup,
            statid,
            value,
            valueType,
        });
    }
    changeName = (id, name) => {
        let entityid = id;
        let value = name;
        this.props.sendMessage({
            operation: 'change entity name',
            entityid,
            value,
        });
    }

    removeTag = (id, tagName) => {
        let entityid = id;
        let tag = tagName;
        this.props.sendMessage({
            operation: 'remove entity tag',
            entityid,
            tag,
        });
    }

    addTag = (id, tagName) => {
        let entityid = id;
        let tag = tagName;
        this.props.sendMessage({
            operation: 'add entity tag',
            entityid,
            tag,
        });
    }

    addItem = (id, itemid) => {
        let entityid = id;
        this.props.sendMessage({
            operation: 'add entity item',
            entityid,
            itemid,
        });
    }

    removeItem = (id, itemid) => {
        let entityid = id;
        this.props.sendMessage({
            operation: 'remove entity item',
            entityid,
            itemid,
        });
    }

    addLimit = (id, name, multiplier, note) => {
        this.props.sendMessage({
            operation: 'add entity limit',
            entityid: id,
            name,
            multiplier,
            note,
        });
    }

    removeLimit = (id, name) => {
        this.props.sendMessage({
            operation: 'remove entity limit',
            entityid: id,
            name,
        });
    }

    setAspects = (id, list) => {
        this.props.sendMessage({
            operation: 'set entity aspects',
            entityid: id,
            type: 'aspects',
            list,
            source: 'EntityListTab',
        })
    }
    setFlaws = (id, list) => {
        this.props.sendMessage({
            operation: 'set entity aspects',
            entityid: id,
            type: 'flaws',
            list,
        })
    }
    
    sortEntities = (e) => {
        const prop = e.target.dataset.prop;
        let reverse = false;
        if(this.state.entitySortProp === prop){
            reverse = !this.state.sortReverse;
        }
        this.setState({
            entitySortProp: prop,
            sortReverse: reverse,
        });
    }
    openTagSelector = (e) => {
        this.setState({
            tagSelector: true,
        })
    }
    changeTagOperation = (e) => {
        this.setState({
            operation: e.target.value
        })
    }
    changeTagLogic = (e) => {
        this.setState({
            logic: e.target.value
        })
    }
    filterEntities = (entities, options) => {
        // console.log(this.state.tagList)
        if(options['parent-op'] === 'doc') {
            entities = entities.filter(x => !x.external);
        }
        if(options['parent-op'] === 'parent') {
            entities = entities.filter(x => x.external);
        }
        let whitelist = options.whitelistTags?.split(',').filter(x => !!x) || [];
        let blacklist = options.blacklistTags?.split(',').filter(x => !!x) || [];
        if(whitelist.length > 0) {
            if(options['white-op'] === 'any') {
                entities = entities.filter(x => this.any(x.tags, whitelist))
            }
            if(options['white-op'] === 'all') {
                entities = entities.filter(x => this.all(x.tags, whitelist))
            }
        }
        if(blacklist.length > 0) {
            if(options['white-op'] === 'any') {
                entities = entities.filter(x => !this.any(x.tags, blacklist))
            }
            if(options['white-op'] === 'all') {
                entities = entities.filter(x => !this.all(x.tags, blacklist))
            }
        }
        return entities;
    }
    any = (a, b) => {
        let success = false;
        b.forEach(x => {
            if(a.includes(x)) {
                return success = true;
            }
        })
        return success;
    }
    all = (a, b) => {
        let success = true;
        b.forEach(x => {
            if(!a.includes(x)) {
                return success = false;
            }
        })
        return success;
    }
    openModal = (e) => {
        let target = e.target.dataset.target;
        // console.log(target)
        this.setState({
            [target]: true,
        })
    }
    closeModal = (e) => {
        let target = e.target.dataset.target;
        this.setState({
            [target]: false,
            tagSelector: false,
        })
    }

    render() {
        let options = queryString.parse(this.props.location.search);
        if (!options) {
            options = {
                'parent-op': 'doc',
            }
        }
        let whitelistTags = options.whitelistTags?.split(',').map(x => x.trim()) || [];
        let blacklistTags = options.blacklistTags?.split(',').map(x => x.trim()) || [];
        let doc = this.props.doc;
        let entities = doc.entities || [];
        entities = this.filterEntities(entities, options);
        entities.sort(CompareProp(this.state.entitySortProp,
            this.state.sortReverse, true));
        let skillCount = {};
        this.skillGroups.forEach(skillGroup => skillCount[skillGroup] = 0);
        doc.skills?.forEach(skill => {
            skillCount[skill.skillGroup]++;
        })
        document.title = ['Entity List', doc.name, 'SGS'].join(' - ');
        // Sgs.calculateAllXp(doc);
        return(<div className='entity-table-wrapper'>
            <EntityTable
                doc = {doc}
                entities = {entities}
                skillCount = {skillCount}
                skillGroups = {this.skillGroups}
                sortEntities = {this.sortEntities}
                openModal = {this.openModal}
                addNewEntity = {this.addNewEntity}
                confirmRemoveEntity = {this.confirmRemoveEntity}
                removeTag = {this.removeTag}
                addTag = {this.addTag}
                removeLimit = {this.removeLimit}
                addLimit = {this.addLimit}
                // modifyLimit = {this.modifyLimit}
                setAspects = {this.setAspects}
                setFlaws = {this.setFlaws}
                changeStat = {this.changeStat}
                sendMessage = {this.props.sendMessage}
                addItem = {this.addItem}
                removeItem = {this.removeItem}
                changeName = {this.changeName}
            />
            <EntityList
                doc = {doc}
                entities = {entities}
                skillCount = {skillCount}
            />
            <Modal
                state={this.state}
                title='Filter entities by Tag'
                target='tagModal'
                closeModal={this.closeModal}
                closeButtonText='close'
            >
                <h3>Parent Doc</h3>
                <select
                    id="parent-op"
                    onChange={this.pushHistory}
                    value={options['parent-op']}
                >
                    <option value='doc'>Doc only</option>
                    <option value='both'>Include both</option>
                    <option value='parent'>Parent only</option>
                </select>
                <h3>Whitelist Tags</h3>
                <select
                    id="white-op"
                    onChange={this.pushHistory}
                    value={options['white-op']}
                >
                    <option value='any'>Any of the following tags</option>
                    <option value='all'>All of the following tags</option>
                </select>
                <br />
                {doc.entityTags?.map((tag) => {return(
                    <label key={tag}>
                        {tag}
                        &nbsp;
                        <input
                            type='checkbox'
                            value={tag}
                            data-list='whitelist'
                            checked={whitelistTags.includes(tag)}
                            onChange={this.pushHistory}
                        />
                    </label>
                )})}
                <h3>Blacklist Tags</h3>
                <select
                    id="black-op"
                    onChange={this.pushHistory}
                    value={options['black-op']}
                >
                    <option value='any'>Any of the following tags</option>
                    <option value='all'>All of the following tags</option>
                </select>
                <br />
                {doc.entityTags?.map((tag) => {return(
                    <label key={tag}>
                        {tag}
                        &nbsp;
                        <input
                            type='checkbox'
                            value={tag}
                            data-list='blacklist'
                            checked={blacklistTags.includes(tag)}
                            onChange={this.pushHistory}
                        />
                    </label>
                )})}
            </Modal>
        </div>)
    }
}

export default withRouter(EntityListTab);