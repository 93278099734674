import DicePool from './DicePool/DicePool.js';
import calcNode from './calcNode.js';
import Xp from './Xp.js';
import groups from './groups.js';
import textFormula from './textFormula.js';
import findEntityById from './findEntityById.js';
import getStatValue from './getStatValue.js';

const maxDepth = 5;

function storeTextFormula(prototype, stat){
    // console.log(prototype);
    if(!prototype.formula || !stat) return;
    stat.formulaText = textFormula(prototype.formula);
}

export function calcAll(doc, depth = 0) {
    // get skillgroups
    if(!!doc.skills && doc.skills.length > 0) {
        let skillGroups = doc.skills.map(skill => skill.skillGroup)
        let distinct = [...new Set(skillGroups)];
        doc.skillGroups = distinct;
        // console.log(distinct);
    }
    doc.entities.forEach(entity => {
        if(!entity.calculated) {
            calcEntity(entity, doc, depth);
        }
    });
    doc.total = doc.entities.reduce((prev, curr) => {
        if(curr.xp > 0 || curr.xp < 0) {
            // console.log(curr.xp)
            return prev + curr.xp;
        }
        else {
            return prev;
        }
    
    }, 0);
    return doc;
}

export function calcSingle(oldDoc, newDoc, depth = 0) {
    let doc = oldDoc;
    console.log(doc)
    let entityId = newDoc.activeEntity.toString();
    let entity = newDoc.entities.find(x => x._id.toString() === entityId);
    console.log('singleEntity', entity);
    // find the index of the active entity
    let targetIndex = doc.entities.findIndex(x => x._id.toString() === entityId);
    console.log('index', targetIndex);
    // replace that index with the new entity
    doc.entities[targetIndex] = entity;
    // reset the calculated field on each entity
    doc.entities.forEach( x => x.calculated = false);
    // perform update on active entity
    calcEntity(entity, doc, depth, true);
    doc.dirty = true;
    // return document
    return doc;
}

function calcEntity(entity, doc, depth, recurseUpwards = false) {
    // if(depth > 1) console.log(entity.name, depth);
    // get items
    if(!!entity.equipment) {
        // console.log(entity.equipment)
        entity.items = entity.equipment.map(x =>
            findEntityById(doc, x));
        entity.items = entity.items.filter(x => !!x);
        entity.items.forEach(item => {
            if(depth < maxDepth && !item.calculated) {
                calcEntity(item, doc, depth + 1);
            }
        });
        // entity.items.forEach(item => console.log(item._id));
        // entity.items.forEach(x => xp += x.xp);
    }
    if(recurseUpwards) {
        // this allows us to recalculate entities that OWN this entity
        // even when recalculating a single item
        doc.entities.forEach( e => {
            if(e.equipment.includes(entity._id.toString())) {
                if(depth < maxDepth && !e.calculated) {
                    calcEntity(e, doc, depth + 1, true);
                }
            }
        });
    }
    
    // do generated stats
    groups.forEach(groupName => {
        let group = entity[groupName];
        if(!!group) {
            group.forEach(stat => {
                let group = doc[groupName];
                // console.log(groupName, stat.name, stat);
                // console.log('\n\n==============', stat.name, '====================================');
                let prototype = group.find(x =>
                    x.name === stat.name);
                if(!!prototype) {
                    stat.prototype = prototype;
                    stat.offset = prototype.offset;
                }
                if(!!prototype && !!prototype.formula) {
                    // console.log(prototype.name, prototype.formulaText);
                    let formula = prototype.formula;
                    storeTextFormula(prototype, stat);
                    // console.log(formula);
                    // console.log(entity.name, prototype.name)
                    let node = calcNode(doc, entity, formula);
                    if(!!node.error) {
                        stat.error = node.error;
                    }
                    // if(node.toString().includes('.')) {
                    //     console.log(node.toString(), node);
                    // }
                    if(typeof node === 'number'
                        || node instanceof Number) {
                        node = Math.round(node);
                    }
                    else if(!!node?.node?.children 
                        && node.node.children.length === 1
                        && (node.node.children[0] instanceof Number
                            || typeof node.node.children[0] === 'number')) {
                            // console.log('round')
                        node = Math.round(node.node);
                    }
                    else {
                        console.error('what??')
                    }
                    // console.log('calculated', stat.name);
                    stat.calculated = node;
                }
            })
        }
    })
    // do xp
    let xpGroups = {
        aspects: 0,
        flaws: 0,
        primaries: 0,
        tacticals: 0,
        thresholds: 0,
        pools: 0,
        skills: 0,
        items: 0,
    };
    let xp = 0;
    if(!!entity.aspects) {
        xp += 10 * entity.aspects.length;
        xpGroups['aspects'] += 10 * entity.aspects.length;
    }
    if(!!entity.flaws) {
        xp -= 10 * entity.flaws.length;
        xpGroups['flaws'] -= 10 * entity.flaws.length;
    }
    groups.forEach(group => {
        if(!!entity[group]) {
            entity[group].forEach(stat => {
                let value = Xp(stat)
                if(group === 'primaries') value *= 2;
                xp += value;
                stat.xp = value;
                xpGroups[group] += value;
            })
        }
    })
    if(!!entity.items) {
        // entity.items = entity.equipment.map(x =>
        //     findEntityById(doc, x));
        // entity.items = entity.items.filter(x => !!x);
        entity.items.forEach(x => {
            xp += x.xp;
            xpGroups['items'] += x.xp;
        });
    }
    if(!!entity.limitations) {
        entity.limitations.forEach(x => {
            let split = x.multiplier.split('/');
            let numerator = split[0];
            let denominator = split.length > 1 ? split[1] : 1; 
            let mult = numerator / denominator;
            xp *= mult;
        });
    }
    xp = Math.round(xp);
    entity.xp = xp;
    // total += xp;
    entity.xpGroups = xpGroups;

    // tally up primary values
    if(!!entity.primaries 
        && entity.primaries.length > 0
        && !!entity.skills
        && entity.skills.length > 0) {
            let dict = {}
            entity.primaries.forEach(x => {
                x.skillPoints = 0;
                dict[x.name] = x;
            });
            entity.skills.forEach(skill => {
                let prototype = doc.skills.find(x => x.name === skill.name);
                if(!prototype) return;
                let primaryName = prototype.skillStat;
                let primary = dict[primaryName];
                // console.log(primaryName, dict, primary);
                if(!primary) return;
                primary.skillPoints += skill.xp > 0 ? skill.xp : 1;
                skill.totalPool = new DicePool(getStatValue(skill) + '+' + getStatValue(primary));
                skill.totalBonus = skill.totalPool.toString();
            })
    }
    entity.calculated = true;
}

export default { calcAll, calcSingle };