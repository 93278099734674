import Modal from "../../Modal.jsx";

function addLimit (id, name, multiplier, note, sendMessage) {
    console.log(id, name, multiplier, note);
    sendMessage({
        operation: 'add entity limit',
        entityid: id,
        name,
        multiplier,
        note,
    });
}
function removeLimit (id, name, sendMessage) {
    console.log(id, name);
    sendMessage({
        operation: 'remove entity limit',
        entityid: id,
        name,
    });
}
function toggleLimit (e, sendMessage) {
    let checked = e.target.checked;
    let entityid = e.target.dataset.entityid;
    let name = e.target.dataset.name;
    let multiplier = e.target.dataset.multiplier;
    let note = e.target.dataset.note;
    // let sendMessage = e.target.dataset.sendmessage;
    // let note = this.state.note;
    if(checked) {
        addLimit(entityid, name, multiplier, note, sendMessage);
    }
    else {
        removeLimit(entityid, name, sendMessage);
    }
}
function onNoteChange(e) {
    console.error('never implemented');
}
function removeTag (entityid, tag, sendMessage) {
    sendMessage({
        operation: 'remove entity tag',
        entityid,
        tag,
    });
}
function addTag (entityid, tag, sendMessage) {
    sendMessage({
        operation: 'add entity tag',
        entityid,
        tag,
    });
}
function toggleTag (e, sendMessage) {
    let checked = e.target.checked;
    let tag = e.target.name;
    let entityid = e.target.dataset.entityid;
    if(checked) {
        addTag(entityid, tag, sendMessage);
    }
    else {
        removeTag(entityid, tag, sendMessage);
    }
}
function addNewTag(e) {
    console.error('never implemented');
}
function changeNewTag(e) {
    console.error('never implemented');
}
function promptNameChange(name, id, changeName){
    let newName = prompt('Rename ' + name + ' to...', name);
    if(newName) {
        console.log(name, newName);
        if(newName !== name) {
            changeName(id, newName);
        }
    }
}

export default function NamePanel (props) {
    let doc = props.doc;
    let entity = props.entity;
    let editable = entity.editable;
    let dtags = doc.entityTags;

    let openModal = props.openModal;
    let closeModal = props.closeModal
    let state = props.state;

    return(
        <div className='panel namepanel'>
            <h2>{entity.name}</h2>
            <p><em>{entity._id}</em></p>
            {editable && <button
                onClick={() => promptNameChange(entity.name, entity._id, props.changeName)}
            >Rename</button>}
            <h3>{entity.xp.toLocaleString()} xp</h3>
            <ul>
                {entity.limitations.map(limit => {return(
                    <li key={limit.name}>
                        Limitation: {limit.name} (×{limit.multiplier}{!!limit.note && ', '}{limit.note})
                        &nbsp;
                        {editable && <button
                            className='delete'
                            onClick={() => removeLimit(
                                entity._id,
                                limit.name,
                                props.sendMessage,
                            )}
                        >
                            x
                        </button>}
                    </li>
                )})}
                {editable && <li>
                    <button
                        onClick={openModal}
                        data-target='limitModal'
                    >
                        ➕Add Limit
                    </button>
                    <Modal
                        state={state}
                        title='Select Limtations'
                        target='limitModal'
                        closeModal={closeModal}
                        closeButtonText='close'
                    >
                        <table>
                            <thead>
                                <tr>
                                    <td>Limit</td>
                                    <td>Multiplier</td>
                                    <td>Enabled</td>
                                    <td>Note</td>
                                </tr>
                            </thead>
                            <tbody>
                                {doc.limitations.map(limit => {
                                let entityLimit = entity.limitations
                                    ?.find(x => x.name === limit.name);
                                return(<tr key={limit.name}>
                                    <td>
                                        <label
                                            htmlFor={limit.name}
                                            className={!!entityLimit ? 'checked' : ''}
                                        >
                                            {limit.name}
                                        </label>
                                    </td>
                                    <td>{limit.multiplier}</td>
                                    <td>
                                        <input
                                            id={limit.name}
                                            type='checkbox'
                                            checked={!!entityLimit}
                                            onChange={(e) => toggleLimit(e, props.sendMessage)}
                                            data-name={limit.name}
                                            data-multiplier={limit.multiplier}
                                            data-entityid={entity._id}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            data-limitname={limit.name}
                                            value={limit.notes}
                                        />
                                    </td>
                                </tr>)})}
                            </tbody>
                        </table>
                        <input
                            value={state.note}
                            onChange={onNoteChange}
                            placeholder='optional limitation note'
                        />
                        <div>
                            {doc.limitations?.map(limit => {return(
                                <button
                                    onClick={addLimit}
                                    data-name={limit.name}
                                    data-multiplier={limit.multiplier}
                                    key={limit.name}
                                    data-entityid={entity._id}
                                >
                                    {limit.name} (×{limit.multiplier})
                                </button>
                            )})}
                        </div>
                    </Modal>
                </li>}
            </ul>
            <h3>Tags</h3>
            <ul>
                {entity.tags.map(tag => {return(
                    <li key={tag}>
                        #{tag}
                        &nbsp;
                        {editable && <button
                            className='delete'
                            onClick={() => removeTag(
                                entity._id,
                                tag,
                                props.sendMessage
                            )}
                        >
                            x
                        </button>}
                    </li>
                )})}
                {editable && <li>
                    <button
                        onClick={openModal}
                        data-target='tagModal'
                    >
                        ➕Add Tag
                    </button>
                    <Modal
                        state={state}
                        title='Select Tags'
                        target='tagModal'
                        closeModal={closeModal}
                        closeButtonText='close'
                    >
                        <table><tbody>
                            {dtags.map(dt => {
                                let eHasTag = entity.tags?.includes(dt);
                                return(
                                <tr key={dt}>
                                    <td>
                                        <label
                                            htmlFor={dt}
                                            className={eHasTag ? 'checked' : ''}
                                        >
                                            {dt}
                                        </label>
                                    </td>
                                    <td>
                                        <input 
                                            type='checkbox'
                                            name={dt}
                                            id={dt}
                                            checked={eHasTag ? true : false}
                                            onChange={e => toggleTag(e, props.sendMessage)}
                                            data-entityid={entity._id}
                                        />
                                    </td>
                                </tr>
                            )})}
                            <tr>
                                <td>
                                    <form
                                        onSubmit={addNewTag}
                                        id='newTagForm'
                                    >
                                        <label>Add a Tag:&nbsp;
                                            <input
                                                placeholder='new tag'
                                                value={state.newTag}
                                                onChange={changeNewTag}
                                            />
                                        </label>
                                    </form>
                                </td>
                                <td>
                                        <input
                                            form='newTagForm'
                                            type='submit'
                                        />
                                </td>
                            </tr>
                        </tbody></table>
                    </Modal>
                </li>}
            </ul>
        </div>
    )
}

// function Editable(props){
//     let entity = props.entity;
//     return (
//         <div className='panel'>
//         <h2>{entity.name}</h2>
//         <p><em>{entity._id}</em></p>
//         <h3>Xp</h3>
//         <ul>
//             <li>{entity.xp.toLocaleString()} xp</li>
//             {entity.limitations.map(limit => {return(
//                 <li key={limit.name}>
//                     Limitation: {limit.name} (×{limit.multiplier}{!!limit.note && ', '}{limit.note})
//                 </li>
//             )})}
//         </ul>
//         <h3>Tags</h3>
//         <ul>
//             {entity.tags.map(tag => {return(
//                 <li key={tag}>
//                     #{tag}
//                     &nbsp;
//                 </li>
//             )})}
//         </ul>
//     </div>
        
//     )
// }
